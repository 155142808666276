@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

body {
  background-color: white;
}


#root {
  height: 100vh;
}
